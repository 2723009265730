<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col
        lg="8"
        md="6"
        class="d-none d-lg-flex align-items-center p-0"
      >
        <splash-screen />
      </b-col>
      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('login.forgot_password') }} ?
            <feather-icon
              icon="LockIcon"
              size="18"
            />
          </b-card-title>

          <p>{{ $t('login.forgot_password_notice') }}</p>
          <!-- form -->
          <validation-observer
            #default="{ invalid, validate }"
            ref="loginValidation"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="validate().then(submitCredentials)"
            >
              <!-- email -->
              <app-input
                id="login-email"
                v-model="email"
                :label="$t('login.email')"
                rules="required|email"
              />

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                {{ $t('login.send_reset') }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <div>
              <b-link :to="{name : 'login'}">
                <feather-icon icon="ArrowLeftIcon" />
                <span> {{ $t('login.back_login') }}</span>
              </b-link>
            </div>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapState, mapActions } from 'vuex'
import splashScreen from '@/views/base/SplashScreen.vue'
import { patchResetPasswordMailRequest } from '@/request/authApi/requests/apiRequests'

export default {
  name: 'ForgotPassword',
  components: {
    splashScreen,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      email: '',
      sideImg: require('@/assets/images/pages/login/airplane.jpg'),
      logoFull: require('@/assets/images/logo/logo.svg'),
    }
  },
  computed: {
    ...mapState('appConfig', ['layout']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (this.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login/airplane-dark.jpg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions('auth', ['login']),

    submitCredentials() {
      const resetPasswordObj = {
        email: this.email,
        resetPasswordUrl: `${window.location.origin}/reset-password`,
      }
      patchResetPasswordMailRequest(resetPasswordObj)
        .then(result => {
          if (result.error) {
            this.toaster(result.error.message, 'danger', 'AlertTriangleIcon')
          } else {
            this.$router.push({ name: 'forgot-password-validation' })
          }
        })
        .catch(() => false)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
