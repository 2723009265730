<template>
  <section class="w-100">
    <span class="brand-logo">
      <b-img style="object-fit: cover; width: 350px" fluid :src="logoFull" alt="logo" />
    </span>
    <!-- Left Text-->
    <div class="w-100 h-100 d-lg-flex align-items-center justify-content-center">
      <b-img class="h-100" fluid-grow :src="imgUrl" alt="Login V2" style="object-fit: cover" />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SplashScreen',
  data() {
    return {
      sideImg: require('@/assets/images/pages/login/airplane.jpg'),
      logoFull: require('@/assets/images/logo/logo.png'),
    }
  },
  computed: {
    ...mapState('appConfig', ['layout']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (this.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login/airplane-dark.jpg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
